"use client";
import { Description, LeadText } from "@moller/design-system";
import { NotFoundIcon } from "../components/icons/index";
import { styled } from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--moller-spacing-xxl);

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--moller-spacing-xxs);

  align-items: center;
`;

const StyledDescription = styled(Description)`
  text-align: center;
`;

const NotFound = () => {
  return (
    <Wrapper>
      <NotFoundIcon />
      <TextContainer>
        <LeadText>404 - Siden finnes ikke</LeadText>
        <StyledDescription>
          For å finne det du leter etter kan du prøve å gå tilbake i
          nettleseren.
        </StyledDescription>
      </TextContainer>
    </Wrapper>
  );
};

export default NotFound;
